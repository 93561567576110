module.exports = `
<p>
    PLEASE READ THE FOLLOWING SOFTWARE AGREEMENT CAREFULLY BEFORE USING THIS
    WEBSITE, INDICATORS, OR PRODUCTS. All users (the “Recipient”) agree to abide by
    and be bound by the terms container herein of this site and agree that access to
    and use of this site, indicators, or products are subject to the following
    software agreement and other applicable law. If you do not agree to these terms,
    please do not use this site, indicators, nor products contained within. Wizbord
    Inc., Wizdough, including its Officers, Members, Employees, Representatives, and
    Agents (henceforth referred to as “Author”) is not a registered investment
    advisor or broker dealer and does not provide investment advice to individuals.
    None of the website content, trading room, blogs, software, indicators, email
    newsletters, affiliated social media pages to include, but not limited to,
    Facebook, YouTube, and Twitter, 3rd party forums, Webinars, voice chat room
    content, or communication from Author otherwise (henceforth referred to as
    “Author Content”) should be construed as a recommendation or solicitation to buy
    or sell any security or as investment advice. All Author Content are provided
    for information and educational purposes only. Your investment decisions are
    solely your responsibility and it is highly recommended that you consult a
    licensed financial advisor or broker, attorney, or your CPA, before making any
    and all investment or financial decision.
    </p>
    <p>
    Always seek advice and guidance from professional, licensed, and registered
    investment advisors, financial advisors, tax advisors, and legal advisors prior
    to making any investment decisions. By viewing Author Content or any content
    created or replicated by Author, you acknowledge that you understand and accept
    the disclosures herein. If any provision in these disclosures is found to be
    invalid, unenforceable, or nonsensical, the remaining provisions will continue
    in full force and effect.
    </p>
    <p>
    Please read this agreement carefully. It contains important terms that affect
    the Recipient and their use of the software and services. By agreeing to these
    terms, or by installing, copying, or using the software, Recipient agrees to be
    bound by the following terms, including the disclaimers contained herein. If
    Recipient does not agree to these terms, do not agree to to these terms,
    install, copy, or use the software or services.
    </p>
    <ol>
    <li>Software License.
    <ol>
    <li><strong>Software</strong>. Software shall mean the software provided by
    Author for Recipient to use on Recipient’s Trading Platform.
    <li><strong>License Grant</strong>. Author grants to Recipient a limited,
    non-transferable license to use the Software in accordance with the terms of
    this Agreement.
    <li><strong>Permitted Uses</strong>. Recipient may install and use the number of
    copies of the software that Recipient has purchased. Additionally, Recipient has
    been granted a license solely for personal, non-commercial use.
    <li><strong>Restricted Uses.</strong>
    <ol>
    <li><strong>Distribution</strong>. Recipient may not distribute, license, loan
    or sell the Software or other content contained or displayed in or by the
    Software.
    <li><strong>Modification</strong>. Recipient may not modify, alter or create any
    derivative works of the Software.
    <li><strong>Reverse Engineering</strong>. Recipient may not reverse engineer,
    decompile, decode, decrypt, disassemble, or in any way derive source code from
    the Software.
    <li><strong>Proprietary Notices</strong>. Recipient may not remove, alter or
    obscure any copyright, trademark or other proprietary rights notice on or in the
    Software.
    </li>
    </ol>
    </li>
    </ol>
    <li>Support and Maintenance.
    <ol>
    <li><strong>Support and Maintenance</strong>. Author has no obligation to
    provide any support, maintenance, or other services.
    <li><strong>Bugs</strong>. Should Recipient identify any issue with the
    Software, Recipient will provide Author with all details necessary to reproduce
    and fix such issue.
    </li>
    </ol>
    <li> Non-Disclosure.
    <ol>
    <li><strong>Confidentiality</strong>. Recipient will ensure the confidentiality
    of all documents, Software, and communications provided by Author.
    <li><strong>Public Disclosure</strong>. Recipient will refrain from making any
    public statement in regards to this agreement, the trading system, Software,
    correspondence between the Author and Recipient, and corresponding results
    unless authorized in writing by Author, or required to be disclosed in a
    judicial proceeding or otherwise required to be disclosed by law or regulation.
    <li><strong>Contravention</strong>. The information, data, methodologies,
    Software, and services provided by the Author are available for purchase and are
    not intended to be published or made available to any person in any jurisdiction
    where doing so would result in contravention of any applicable laws or
    regulations. Accordingly, if it is prohibited to make such Software available in
    the Recipient’s jurisdiction or to the Recipient (by reason of nationality,
    residence or otherwise) it is not directed at the Recipient. Before making a
    purchase of any software or services from the Author, Recipient must be
    satisfied that doing so will not result in such a contravention and is not so
    prohibited, and by proceeding to review them Recipient will be confirming that
    this is not the case.
    </li>
    </ol>
    <li>Subscription Fees.
    <ol>
    <li><strong>Auto Renewal. </strong>Recipient’s membership will automatically
    renew for the monthly, quarterly, annual or other subscription period originally
    signed up for, until Recipient notifies Author of decision to terminate
    membership and completes the cancellation procedures through the Recipient’s
    online account portal before the end of the existing subscription period.
    Accordingly, Recipient agrees that any membership fee or subscription will be
    billed automatically at the beginning of each renewal period at the prevailing
    rate to the payment method used in the most recent transaction.
    <li><strong>Subscription Term Modification. </strong>Recipient may modify the
    length of the subscription service selected. Upgrades to a lengthier term will
    occur immediately. Downgrades to a shorter term will result in a credit of the
    remaining length of the existing term and can only be used towards the new
    subscription term. Credit balance in the Recipient’s account will not be
    refunded and is only permitted to be used towards a subscription service
    provided by the Author.
    <li><strong>Insufficient Funds / Failed Payment. </strong>Any failed payment or
    insufficient funds will result in the immediate termination of the Recipient’s
    subscription service.
    <li>The Recipient may request to cancel the renewal of the subscription through
    the online account portal prior to the end of their existing subscription. Any
    credit remaining in the Recipient’s account balance at the end of the term, can
    only be used for the renewal of a subscription service.
    <li><strong>Errors/Omissions. </strong>Any typographical, clerical or other
    accidental errors or omissions in any sales literature, quotation, price list,
    acceptance of offer, invoice or other document or information issued by the
    Author shall be subject to correction without any liability on the part of the
    Author.
    </li>
    </ol>
    <li>Refunds.
    <ol>
    <li><strong>No Refunds</strong>. All fees are non-refundable.
    </li>
    </ol>
    <li>Warranties.
    <ol>
    <li><strong>No Warranties.</strong>
    <ol>
    <li><strong>As Is.</strong> The Software and Services are provided “as is,” with
    all faults, defects and errors, and without warranty of any kind.
    <li><strong>Bugs and Errors.</strong> Author does not warrant that the Software
    and Services will be free of bugs, errors, viruses or other defects, and Author
    shall have no liability of any kind for the use of or inability to use the
    software, the software content or any associated service.
    </li>
    </ol>
    <li><strong>Disclaimer of All Other Warranties</strong>. Author disclaims to the
    fullest extent authorized by law any and all other warranties, whether express
    or implied, including, without limitation, any implied warranties of
    merchantability or fitness for a particular purpose.
    </li>
    </ol>
    <li>Disclosures & Disclaimers.
    <ol>
    <li><strong>NinjaTrader Affiliation.</strong> NinjaTrader® is a registered
    trademark of NinjaTrader Group, LLC. No NinjaTrader company has any affiliation
    with the Author’s products or services described herein, or any interest,
    ownership or otherwise, in any such product or service, or endorses, recommends
    or approves any such product or service made available by Author.
    <li><strong>Risk of Loss</strong>. Trading stocks, futures, options or spot
    currencies involves substantial risk and there is always the potential for loss.
    People can and do lose money. Past performance does not guarantee future results
    and is not indicative of future performance. Recipient’s trading results may
    vary. Because the risk factor is high in trading the markets, only genuine
    “risk” funds should be used in such trading. If Recipient does not have the
    extra capital that Recipient can afford to lose, Recipient should not trade in
    the markets. No “safe” trading system has ever been devised, and no one can
    guarantee profits or freedom from loss.
    <li><strong>Required Disclosure and Other Trading Disclosures</strong>.
    <ol>
    <li><strong>All Results.</strong> All trading results presented by Author in
    writing, picture, video, or otherwise are based on simulated or hypothetical
    performance results that have certain inherent limitations. Unlike the results
    shown in an actual performance record, these results do not represent actual
    trading. Also, because these trades have not actually been executed, these
    results may have under-or over-compensated for the impact, if any, of certain
    market factors, such as lack of liquidity. Simulated or hypothetical trading
    programs in general are also subject to the fact that they are designed with the
    benefit of hindsight. No representation is being made that any account will or
    is likely to achieve profits or losses similar to these being shown.
    <li><strong>Hypothetical Results. </strong>All trading results presented by
    Author in their marketing materials are based on simulated or hypothetical
    performance results that have certain inherent limitations. Unlike the results
    shown in an actual performance record, these results do not represent actual
    trading. Also, because these trades have not actually been executed, these
    results may have under-or over-compensated for the impact, if any, of certain
    market factors, such as lack of liquidity. Simulated or hypothetical trading
    programs in general are also subject to the fact that they are designed with the
    benefit of hindsight. No representation is being made that any account will or
    is likely to achieve profits or losses similar to these being shown.
    <li><strong>Hypothetical Disclosure</strong>. HYPOTHETICAL OR SIMULATED
    PERFORMANCE RESULTS HAVE MANY INHERENT LIMITATIONS, SOME OF WHICH ARE DESCRIBED
    BELOW. NO REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO
    ACHIEVE PROFITS OR LOSSES SIMILAR TO THOSE SHOWN. IN FACT THERE ARE FREQUENTLY
    SHARP DIFFERENCES BETWEEN HYPOTHETICAL OR SIMULATED PERFORMANCE RESULTS AND THE
    ACTUAL RESULTS SUBSEQUENTLY ACHIEVED BY ANY PARTICULAR TRADING SYSTEM. ONE OF
    THE LIMITATIONS OF HYPOTHETICAL OR SIMULATED PERFORMANCE RESULTS IS THAT THEY
    ARE GENERALLY PREPARED WITH THE BENEFIT OF HINDSIGHT. IN ADDITION, HYPOTHETICAL
    OR SIMULATED PERFORMANCE TRADING DOES NOT INVOLVE FINANCIAL RISK, AND NO
    HYPOTHETICAL OR SIMULATED TRADING RECORD CAN COMPLETELY ACCOUNT FOR THE IMPACT
    OF FINANCIAL RISK IN ACTUAL TRADING. FOR EXAMPLE, THE ABILITY TO WITHSTAND
    LOSSES OR TO ADHERE TO A PARTICULAR TRADING PROGRAM IN SPITE OF TRADING LOSSES
    ARE MATERIAL POINTS WHICH CAN ALSO ADVERSELY AFFECT ACTUAL TRADING RESULTS.
    THERE ARE NUMEROUS OTHER FACTORS RELATED TO THE MARKETS IN GENERAL OR THE
    IMPLEMENTATION OF ANY SPECIFIC TRADING PROGRAM WHICH CANNOT BE FULLY ACCOUNTED
    FOR IN THE PREPARATION OF HYPOTHETICAL OR SIMULATED PERFORMANCE RESULTS AND ALL
    OF WHICH CAN ADVERSELY AFFECT ACTUAL TRADING RESULTS.
    <li><strong>No Recommendation for Buy or Sell</strong>. Nothing presented by the
    Author through the provided Software or other Services should be construed as a
    recommendation to buy or sell any security. Trading in Commodity Futures is very
    risky. There is a possibility of substantial financial loss, greater even than
    monies initially invested. Simulated, hypothetical performance results have
    certain inherent limitations, due to the fact that the trades have not actually
    been executed.
    <li><strong>Simulated Results</strong>. Simulated trading results, in general,
    may be influenced by the fact that the algorithms which generated them were
    designed in consideration of historical trends, and with the benefit of
    hindsight. Past real or hypothetical performance is not a guarantee of future
    results, real or hypothetical. No representation is made that any trading
    account would, or would be likely to achieve profits or losses similar to the
    real or hypothetical results. Author is held harmless and is without liability
    regarding any use whatsoever of the trading system software and services.
    <li><strong>Trading may not be Suitable</strong>. Trading may not be suitable
    for all potential users of the Author’s Software and Services. Recipient, and
    not Author, assumes the entire cost and risks of any trading Recipient may
    choose to undertake. Under no circumstances shall Author be liable for any
    special or consequential damages that result from the use of or the inability to
    use, the materials, if any, provided by Author. Applicable law may not allow the
    limitation or exclusion of liability or incidental or consequential damages, so
    the above limitation or exclusion may not apply to Recipient. In no event shall
    Author’s total liability to Recipient for all damages, losses and causes of
    action, whether contract or tort (including, but not limited to negligence), or
    otherwise exceed the amount paid by Recipient for the current year, if any, for
    use of materials provided by Author.
    <li><strong>Investment Advice</strong>. Author is not a registered broker
    dealer, financial advisor, or investment advisor. Author does not provide
    personal investment advice. Always seek advice and guidance from professional,
    licensed, and registered investment advisors, financial advisors, tax advisors,
    and legal advisors prior to making any investment decisions.
    <li><strong>Methodology and System</strong>. Any trading methodology, Software,
    or system presented, suggested or recommended by Author are ideas that are
    subject to change without notice. The ultimate choice and responsibility of a
    trading methodology, Software, and/or system is Recipient’s alone. Any results
    or performance numbers are hypothetical, apply only to the current version of
    trading methodologies, Software, and/or systems, and are not intended to show
    the results of past versions of methodologies, Software, or systems. Author is
    not responsible for notifying Recipient about changes in methodologies or
    systems and has neither the right nor responsibility to alter what is traded in
    Recipient’s accounts.
    <li><strong>Trade Signals</strong>. Author makes a good faith effort to see that
    all trading signals are correctly generated but cannot be held liable for missed
    or erroneous trades or for operation issues on Recipient’s trading platform and
    trading system(s). Additionally, from time to time, depending on market
    conditions, the Author may determine it is best for signal performance to pause
    the release of new signals until market conditions improve. There are no
    credits/refunds for days in which signals are not released as it is part of the
    Author’s overall strategy of identifying and maintaining high quality actionable
    trade signals.
    <li><strong>Results</strong>. No representation is being made that any account
    will achieve similar results to any other account or actual or hypothetical
    performance numbers presented in the Author’s site or materials. Results can
    vary significantly from brokerage to brokerage, depending upon many factors not
    under the control of the Author.
    <li><strong>Trading Platform. </strong>Author trading signals, charts, Software,
    methodologies, and system are functional only on specified trading platforms. It
    is the Recipient’s responsibility to obtain, at a separate fee, the appropriate
    trading platform (e.g., NinjaTrader, TradeStation, etc.) to receive the Author
    provided trading signals.
    <li><strong>Recipient Acknowledge.</strong> By signing this agreement, or by
    installing, copying, or using the software, Recipient agrees they fully
    understand all representations made, or that have been made by Author are based
    on hypothetical trading results and Recipient fully understand no NinjaTrader
    company has any affiliation with the Author’s products or services herein, or
    otherwise.
    </li>
    </ol>
    <li><strong>Data Feeds</strong>. Differences between data feeds might not only
    alter the entry and exit times and prices used by separate instance of the same
    trading system, in some cases these data feed differences will lead to entirely
    different trading decisions and outcome.
    <li><strong>Reasonable Care</strong>. Author has taken all reasonable care and
    precaution to ensure that information about the System and Services is fair and
    accurate, or has been compiled from sources believed to be reliable.
    Nevertheless, the Author does not make any representations or warranty, express
    or implied, as to the accuracy, completeness, or fitness for any purpose or use
    of the Software and Services. The Software may not in all cases be current, and
    it is subject to continuous change. Accordingly, Recipient should not rely on
    any part of the Software as authoritative or a substitute for the exercise of
    Recipient’s own skill and judgment in making any investment or other decision.
    Author does not warrant that the Software is error free, and will not be liable
    for any direct, indirect, or consequential loss arising from any use of or
    reliance on this Software.
    <li><strong>Author Initiated Cancellation</strong>. The Author maintains the
    right to cancel and / or terminate any and all subscription services and/ or
    Software for any reason, at any time, and without notice.
    </li>
    </ol>
    <li>Acknowledgements.
    <ol>
    <li><strong>Ownership of Software</strong>. Author or its Third Party Content
    Providers own the title, copyright, and other intellectual property rights in
    the Software.
    <li><strong>Consent to Use of Data</strong>. Recipient agrees that Author and
    its affiliates may collect and use technical information gathered as part of the
    product support services. Author may use this information solely to improve
    products and services and will not disclose this information in a form that
    personally identifies the Recipient.
    <li><strong>Government End Users</strong>. If the Software and related
    documentation are supplied to or purchased by or on behalf of the United States
    Government, then the Software is deemed to be “commercial software” as that term
    is used in the Federal Acquisition Regulation system. Rights of the United
    States shall not exceed the minimum rights set forth in FAR 52.227-19 for
    “restricted computer software”. All other terms and conditions of this Software
    License apply.
    </li>
    </ol>
    <li>Remedies.
    <ol>
    <li><strong>Damages</strong>. If the Software, Services, or this agreement’s
    terms are otherwise breached by the Recipient, the Recipient will be liable for
    each instance of breach multiplied by the initial sign-up fee plus the yearly
    subscription price for the Author’s product at the time of breach.
    <ol>
    <li><strong>Example One</strong>. If the Recipient shares the Software provided
    by the Author, the Recipient will be liable for each download of the Software
    multiplied by the sum of the sign-up fee plus the yearly subscription price for
    the Author’s product at the time of breach. If the download is further shared,
    the Recipient will be liable for each instance of dissemination as described
    above. This example is not all inclusive and is illustrative of how damages will
    be applied and calculated.
    </li>
    </ol>
    <li><strong>Other fees and costs</strong>. The Author shall be entitled to
    recover any fees and costs, including reasonable attorneys’ fees, in obtaining
    any such relief.
    <li><strong>Remedies Cumulative</strong>. All rights and remedies provided in
    this Agreement are cumulative and not exclusive of any other rights or remedies
    that may be available to the parties, whether provided by law, equity, statute,
    or in any other agreement between the parties or otherwise.
    </li>
    </ol>
    <li>General Provisions.
    <ol>
    <li><strong>Entire Agreement</strong>. This Agreement sets forth our entire
    agreement with respect to the Software and the subject matter hereof and
    supersedes all prior and contemporaneous understandings and agreements whether
    written or oral.
    <li><strong>Amendment</strong>. Author reserves the right, in its sole
    discretion, to amend this Agreement from time to time and will provide the
    Recipient with notice of the update in a manner consistent with “Notices”
    subsection 10.4, below. At any time following the Author’s update, the
    Recipient’s use of Software, subscription service, member’s forums, and/or
    access of any of the Author’s content, to include but not limited to
    ArcasTrading.com, will constitute as the Recipient’s acknowledgement and
    acceptance of the updated Agreement.
    <li><strong>Assignment</strong>. Recipient may not assign this Agreement or any
    of its rights under this Agreement without the prior written consent of the
    Author and any attempted assignment without such consent shall be void.
    <li><strong>Notices</strong>. Author may deliver any notice required by this
    Agreement via pop-up window, dialog box, email, or other device, even though you
    may not receive the notice unless and until you launch the Software. Any such
    notice will be deemed delivered on the date Author first makes it available
    through the Software, irrespective of the date of receipt.
    <li><strong>Governing Law</strong>. This Agreement shall be governed, construed,
    and enforced in accordance with the laws of the State of Virginia, without
    regard to its conflict of laws rules.
    <li><strong>Severability</strong>. If any provision of this Agreement is held by
    a court of competent jurisdiction to be invalid, illegal, or unenforceable, the
    remainder of this Agreement will remain in full force and effect.
    <li><strong>Waiver</strong>. Failure or neglect by either party to enforce at
    any time any of the provisions of this license Agreement shall not be construed
    or deemed to be a waiver of that party’s rights under this Agreement.
    <li><strong>Headings</strong>. The headings of sections and paragraphs of this
    Agreement are for convenience of reference only and are not intended to
    restrict, affect or be of any weight in the interpretation or construction of
    the provisions of such sections or paragraphs.
`;